<script lang="ts" setup>
import SkrButton from '@/components/button/SkrButton.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'

const props = defineProps<{
  loading: boolean
}>()

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'action', data: Dialogs['DialogFairFlatMigration']['contextData']): void
}>()

const { t } = useI18n()

const submit = (type: 'migrate' | 'free') => {
  if (props.loading) return
  emit('action', { type })
}
</script>

<template>
  <v-dialog :model-value="true" max-width="796" persistent>
    <v-card>
      <v-card-title>
        {{ t('dialog.ff_migration.title') }}
      </v-card-title>
      <v-container class="px-8 py-10" fluid>
        <v-row>
          <v-col cols="12" sm="7" class="d-flex flex-column space-y-6">
            <div class="text-h6 font-weight-bold text-grey-darker">{{ t('dialog.ff_migration.content.title') }}</div>
            <div>
              <div>{{ t('dialog.ff_migration.content.benefits.text') }}</div>
              <ul>
                <li>{{ t('dialog.ff_migration.content.benefits.benefit_1') }}</li>
                <li>{{ t('dialog.ff_migration.content.benefits.benefit_2') }}</li>
                <li>{{ t('dialog.ff_migration.content.benefits.benefit_3') }}</li>
              </ul>
            </div>
            <div>
              <i18n-t keypath="dialog.ff_migration.content.legal_text">
                <template #gtc>
                  <a :href="t('global.accept_gtc.link_url')" target="_blank" rel="noopener">{{
                    t('global.accept_gtc.label_link_text')
                  }}</a>
                </template>
                <template #dpa>
                  <a :href="t('business.setup.gtc_dpa.dpa_link_url')" target="_blank" rel="noopener">{{
                    t('business.setup.gtc_dpa.dpa_link_text')
                  }}</a>
                </template>
              </i18n-t>
            </div>
            <skr-button data-cy="dialog_action_button" size="lg" :loading="loading" @click="submit('migrate')">
              {{ t('dialog.ff_migration.action') }}
            </skr-button>
            <div class="text-body-2">
              <i18n-t keypath="dialog.ff_migration.content.free_disclaimer.text">
                <template #freeLink>
                  <span class="text-primary text-decoration-underline cursor-pointer" @click="submit('free')">{{
                    t('dialog.ff_migration.content.free_disclaimer.link_text')
                  }}</span>
                </template>
              </i18n-t>
            </div>
          </v-col>
          <v-col v-if="$vuetify.display.mdAndUp" cols="12" sm="5" align-self="center">
            <responsive-image source="dialog-company-building" :width="261" :height="272" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
